<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row justify="center" no-gutters> Agregar Subscripción </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <v-row>
            <v-col
              cols="6"
              v-for="(subscription, index) in subscriptions"
              :key="index"
            >
              <NewSubscriptionComponent
                :index="index"
                :subscriptions="subscriptions"
                :subscription="subscription"
              />
            </v-col>
          </v-row>

          <v-container>
            <v-row justify="center" no-gutters>
              <v-btn color="info" small @click="addChannel()"
                >Agregar Canal</v-btn
              >
            </v-row>
          </v-container>

          <v-snackbar v-model="device_saved">
            {{ device_saved_ok }}

            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NewSubscriptionComponent from "./NewSubscriptionComponent.vue";
import api from "../services/api";

export default {
  components: { NewSubscriptionComponent },
  data() {
    return {
      device_saved: false,
      device_saved_ok: "",
      subscriptions: [],
      isFormValid: false,
    };
  },

  methods: {
    saveDevice(device) {
      api.new_device(device).then((response) => {
        if (response.status == 200) {
          this.device_saved_ok = "Dispositivo agregado con éxito";
          this.device_saved = true;
          this.$emit("close");
        }
      });
    },
    addChannel() {
      this.subscriptions.push({
        channelId: "",
        credits: 0,
      });
    },
  },
  computed: {
    panel_info() {
      if (this.subscription.channelId) {
        return this.all_channels.find(
          (channel) => channel.id == this.subscription.channelId
        ).panel
      } else {
        return {
          name: "",
          credits: 0,
        };
      }
    },
    available_credits() {
      if (Object.keys(this.panel_info).length > 0) {
        return this.panel_info.credits - this.subscription.credits;
      } else {
        return 0;
      }
    },
  },  
  mounted() {},
};
</script>

<style>
</style>