<template>
	<v-container>
		<v-container>
			<v-row>
				<v-btn color="primary" @click="new_channel_dialog = true">Agregar</v-btn>
			</v-row>
		</v-container>

		<v-dialog v-if="new_channel_dialog" v-model="new_channel_dialog" @input="close" max-width="400">
			<v-sheet>
				<v-card>
					<v-card-title>Agregar Nuevo Paquete</v-card-title>
					<v-card-text>
						<v-row>
							<v-col>
								<v-text-field label="Nombre" v-model="new_channel.name" />
							</v-col>
							<v-col>
								<v-text-field label="Costo" v-model="new_channel.cost" type="number" />
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn fab small color="primary" @click="save_new_channel()"><v-icon>mdi-content-save</v-icon></v-btn>
					</v-card-actions>
				</v-card>
			</v-sheet>
		</v-dialog>

		<v-container>
			<v-row>
				<v-col lg="4" sm="4" md="4" v-for="(subscription, index) in subscriptions_aggregations" :key="subscription.id">
					<v-sheet>
						<v-card elevation="1" :color="subscription_class(subscription)">
							<v-card-title>
								<v-row align-content="center" align="baseline">
									<v-col align="center">
										<p v-if="!subscription.edit" class="text-body-2">{{ subscription.name }}</p>
										<v-text-field
											v-else
											class="text-body-2"
											v-model="subscription.name"
											:readonly="!subscription.edit"
											:outlined="subscription.edit"
											label="Nombre"
										/>
									</v-col>
									<v-col align="center">
										<p v-if="!subscription.edit" class="text-body-2">${{ subscription.cost }}</p>
										<v-text-field
											v-else
											class="text-body-2"
											v-model="subscription.cost"
											:readonly="!subscription.edit"
											:outlined="subscription.edit"
											prefix="$"
											type="number"
											label="Costo"
										/>
									</v-col>
								</v-row>
							</v-card-title>
							<v-card-text>
								<SubscriptionTableVue v-if="!subscription.deleted" :subscription="subscription" />
							</v-card-text>
							<v-card-actions>
								<v-row>
									<v-col cols="1" align="center">
										<v-btn icon small color="primary" @click="toggle_edit(subscription, index)">
											<v-icon v-if="subscription.edit" color="red">mdi-close</v-icon>
											<v-icon v-else>mdi-pencil</v-icon>
										</v-btn>
									</v-col>
									<v-col cols="1">
										<v-btn v-if="subscription.edit" icon small color="success" @click="update_channel(subscription.id, subscription.cost, subscription.name)"
											><v-icon>mdi-check</v-icon></v-btn
										>
									</v-col>
									<v-spacer />
									<v-col>
                                        <v-btn v-if="subscription.deleted" x-small color="success" @click="enable_channel(subscription.id)">Activar</v-btn>
										<v-btn v-else x-small color="error" @click="deactivate_channel(subscription)">Desactivar</v-btn>
									</v-col>
								</v-row>
							</v-card-actions>
						</v-card>
						<v-divider></v-divider>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-if="deactivate_dialog" v-model="deactivate_dialog" @input="close" max-width="900">
			<v-sheet>
				<v-card>
					<v-card-title>Desactivar {{ subscription_to_deactivate.name }}</v-card-title>
					<v-card-text>
						<SubscriptionTableVue
							:subscription="subscription_to_deactivate"
							:deactivate="true"
							@get_subscriptions_aggregations="get_subscriptions_aggregations()"
						/>
					</v-card-text>
				</v-card>
			</v-sheet>
		</v-dialog>
	</v-container>
</template>
<script>
import { mapState } from "vuex";
import api from "../services/api";
import SubscriptionTableVue from "./SubscriptionTable.vue";
export default {
	components: {
		SubscriptionTableVue,
	},
	data() {
		return {
			subscriptions_aggregations: [],
			deactivate_dialog: false,
			new_channel_dialog: false,
			subscription_to_deactivate: {},
			new_channel: {},
		};
	},
	methods: {
		save_new_channel() {
			api.save_new_channel(this.new_channel).then((response) => {
				if (response.status === 200) {
					this.new_channel_dialog = false;
					this.get_subscriptions_aggregations();
					this.$store.dispatch("get_all_channels");
				}
			});
		},
		close() {
			this.new_channel_dialog = false;
			this.deactivate_dialog = false;
		},
		deactivate_channel(subscription) {
			this.subscription_to_deactivate = subscription;
			this.deactivate_dialog = true;
		},
		update_channel(id, cost, name) {
			api.update_channel(id, cost, name).then(() => {
				this.get_subscriptions_aggregations();
			});
		},
		get_subscriptions_aggregations() {
			this.deactivate_dialog = false;
			api.get_subscriptions_aggregations().then((response) => {
				this.subscriptions_aggregations = response.data;
			});
		},
		toggle_edit(subscription, index) {
			if (subscription.edit) {
				this.get_subscriptions_aggregations();
				this.subscriptions_aggregations.splice(index, 1, { ...subscription, edit: false });
			} else {
				this.subscriptions_aggregations.splice(index, 1, { ...subscription, edit: true });
			}
		},
        enable_channel(channelId) {
            api.enable_channel(channelId).then((response) => {
                if (response.status === 200) {
                    this.get_subscriptions_aggregations()
                    this.$store.dispatch("get_customers")                    
                }
            })
        },
		subscription_class(subscription) {
            if (subscription.deleted) {
                return '#EF9A9A'
            }
		},
	},
	computed: {
		...mapState({
			all_channels: (state) => state.all_channels,
		}),
	},
	mounted() {
		this.get_subscriptions_aggregations();
	},
};
</script>
