<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <v-row>
          <p class="text-h4">Ingreso</p>
        </v-row>
        <!-- <div :class="controlClass">
        <label class="label" v-text="labels.user"></label>
        <v-text-field
          :class="inputClass"
          type="text"
          :placeholder="userPlaceholder"
          v-model="login.user"
        />
      </div> -->
        <v-row>
          <v-text-field
            label="Usuario"
            type="text"
            v-model="user_data.username"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-text-field
            label="Contraseña"
            type="password"
            v-model="user_data.password"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-btn color="info" @click="handleLogin">Ingresar</v-btn>
        </v-row>
        <!-- <div :class="controlClass">
          <label class="label" v-text="labels.password"></label>
          <input
            :class="[inputClass, { 'reveal-password': passwordReveal }]"
            :type="typePassword"
            :placeholder="passwordPlaceholder"
            v-model="login.password"
          />
          <a
            v-if="passwordReveal && typePassword === 'password'"
            @click.prevent="toggleTypePassword"
          >
            <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
          </a>
          <a
            v-else-if="passwordReveal && typePassword === 'text'"
            @click.prevent="toggleTypePassword"
          >
            <i class="fa fa-eye-slash fa-2x" aria-hidden="true"></i>
          </a>
        </div> -->

        <!-- <div :class="controlClass">
          <a
            @click.prevent="handleLogin"
            type="submit"
            :class="buttonClass"
            v-text="labels.button"
          ></a>
        </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",

  props: {
    labels: {
      type: Object,
      default() {
        return {
          title: "Login",
          user: "User",
          password: "Password",
          button: "Login",
        };
      },
    },

    userPlaceholder: String,
    passwordPlaceholder: String,
    passwordReveal: Boolean,
    controlClass: {
      type: String,
      default: "control",
    },

    inputClass: {
      type: String,
      default: "input",
    },

    buttonClass: {
      type: String,
      default: "button",
    },

    loginRoute: {
      type: String,
      default: "login",
    },

    userRoute: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      loading: false,
      typePassword: "password",
      user: {},
      user_data: {
        username: "",
        password: "",
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions(["login"]),
    handleLogin() {
      //   this.loading = true;
      this.login(this.user_data).then(() => {
        this.$store.dispatch("get_notifications", this.$store.getters.user.id)
        this.$router.push({ name: "home" });
      });
    },
    toggleTypePassword() {
      this.typePassword =
        this.typePassword === "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.reveal-password {
  width: calc(100% - 40px);
  margin-right: 8px;
}
</style>