<template>
	<v-card>
		<v-card-title>Editar</v-card-title>
		<v-card-text>
			<v-row>
				<v-col>
					<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="subscription_to_edit.contract_date"
								label="Fecha de inicio"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker v-model="subscription_to_edit.contract_date" @input="menu = false" locale="es"></v-date-picker>
					</v-menu>
				</v-col>

				<v-col><v-text-field label="Créditos" v-model="subscription_to_edit.credits" type="number"/></v-col>
				<v-col><v-text-field label="Fecha Próximo Pago" v-model="next_payment_date" disabled/></v-col>
			</v-row>
            <v-row>
                <v-col>
                    <v-text-field v-model="subscription_to_edit.panel_user" label="Usuario Panel"/>
                </v-col>
                <v-col>
                    <v-text-field v-model="subscription_to_edit.panel_password" label="Contraseña Panel"/>
                </v-col>
            </v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn color="success" @click="update_subscription">Guardar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import moment from "moment";
import api from "../../services/api";

export default {
	props: ["subscription"],
	data() {
		return {
			menu: false,
			next_payment_date: moment(this.subscription.next_payment_date).toJSON().split("T")[0],
			subscription_to_edit: {
				id: this.subscription.id,
				contract_date: moment(this.subscription.contract_date).toJSON().split("T")[0],
				credits: this.subscription.credits,
                panel_user: this.subscription.panel_user,
                panel_password: this.subscription.panel_password
			},
		};
	},
	watch: {
		subscription_to_edit: {
			handler() {
				this.next_payment_date = moment(this.subscription_to_edit.contract_date).add(this.subscription_to_edit.credits, "months").toJSON().split("T")[0];
			},
			deep: true,
		},
	},
	methods: {
		update_subscription() {
			api
				.update_subscription({
					id: this.subscription_to_edit.id,
					contract_date: this.subscription_to_edit.contract_date,
					credits: Number(this.subscription_to_edit.credits),
					next_payment_date: this.next_payment_date,
                    panel_user: this.subscription_to_edit.panel_user,
                    panel_password: this.subscription_to_edit.panel_password
				})
				.then((response) => {
					if (response.status == 200) {
						this.$emit("close");
					}
				});
		},
	},
};
</script>
