import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import es from 'vuetify/lib/locale/es'


Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es'
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },    
});
