import store from '../store'

const user = store.getters.user

export default {
    getLocalRefreshToken() {
        return user?.refresh_token;
      },
      getLocalAccessToken() {
        return user?.access_token;
      },
      updateLocalAccessToken(access_token) {
        store.dispatch("update_access_token", access_token)
      }
}