<template>
  <v-card>
    <v-card-text>
      <p>{{ panel_info.name }}</p>
      <p>Créditos: {{ available_credits }}</p>

      <v-select
        v-model="subscriptions[index].channelId"
        :items="all_channels"
        item-text="name"
        item-value="id"
      >
      </v-select>

      <v-text-field
        label="Créditos"
        v-model="subscriptions[index].credits"
        hide-details
        single-line
        type="number"
        :rules="[credits_rules.min, credits_rules.max]"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["subscription", "index", "subscriptions"],
  data() {
    return {
      credits_rules: {
        min: (value) => value > 1,
        max: (value) => value <= this.panel_info.credits,
      },
      all_channels: this.$store.getters.all_channels
    };
  },
  watch: {
    panel_info: function(){
        this.subscriptions[this.index].panelId = this.panel_info.id
    }
  },
  computed: {
    panel_info() {
      if (this.subscription.channelId) {
        return this.all_channels.find(
          (channel) => channel.id == this.subscription.channelId
        ).panel
      } else {
        return {
          name: "",
          credits: 0,
        };
      }
    },
    available_credits() {
      if (Object.keys(this.panel_info).length > 0) {
        return this.panel_info.credits - this.subscription.credits;
      } else {
        return 0;
      }
    },
  },
  mounted() {
  }
};
</script>