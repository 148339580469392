<template>
  <section class="section"></section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(["logout"])
  },
  mounted() {
    this.logout().then(() => {
        this.$router.push({ name: 'login' })
    })
  },
};
</script>

<style>
</style>