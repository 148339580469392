<template>
  <v-row>
    {{ get_date | formatDate }}
    {{ time }}
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      time: null,
    };
  },
  computed: {
    get_date() {
      return moment();
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style></style>
