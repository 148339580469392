<template>
	<v-container fluid>
		<h1>Home2</h1>
		<v-container fluid>
			<v-container>
				<v-row align="center">
					<v-col cols="1"><v-text-field field v-model="year" type="number" label="Año" /></v-col>
					<v-col cols="1"><v-text-field field v-model="month" type="number" label="Mes" /></v-col>
					<v-col cols="2"><v-btn @click="get_payments_aggregations()" color="primary" elevation="2" small>Ir</v-btn></v-col>
				</v-row>
			</v-container>

			<v-simple-table v-if="aggregated_data.joined_data" >
				<thead>
					<tr>
						<th v-for="(header, i) in Object.keys(aggregated_data.joined_data[0])" :key="i" class="text-left">
							<p v-if="header === 'payment_date'">DÍA</p>
                            <p v-else>{{ header.replace(/_/g, " ").toUpperCase() }}</p>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr v-for="row in aggregated_data.joined_data" :key="row.payment_date">
						<td v-for="(value, key) in row" :key="key" class="text-center">
							<p v-if="key === 'payment_date'">

							<v-btn color="#F9F7F7" elevation="2" class="font-weight-bold" rounded small @click="get_payment_details(new Date(value).getUTCDate())">
								{{ new Date(value).getUTCDate() }}
							</v-btn>
                            </p>

							<p v-else-if="key === 'monto_total' || key === 'total_creditos'" class="total">{{ value }}</p>
							<p v-else>{{ value }}</p>
						</td>
					</tr>
					<tr class="footer">
						<td></td>
						<td v-for="(value, name) in aggregated_data.totals.payment_methods" :key="name" class="text-center">
                            <v-btn @click="get_payment_details(null, null, name.toUpperCase())" class="font-weight-bold" rounded>${{ value }}</v-btn>
						</td>
                        <td class="text-center">{{ aggregated_data.totals.monto_total }}</td>
						<td v-for="(value, name) in aggregated_data.totals.channels" :key="name" class="text-center">
                            <!-- <v-btn @click="get_payment_details(null, name.toUpperCase(), null)" class="font-weight-bold" rounded>{{ value }}</v-btn> NOT IMPLEMENTED YET IN OLD HOME-->
                            {{ value }}
						</td>
                        <td class="text-center">{{ aggregated_data.totals.total_creditos }}</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-container>

		<v-dialog v-model="payment_details_dialog" @input="close">
			<v-card>
				<v-card-title>
					<v-row>Pagos</v-row>
				</v-card-title>
				<v-card-text>
					<v-data-table :items="Object.values(payment_details)" :headers="payment_details_headers" disable-pagination hide-default-footer :loading="loading">
						<template v-slot:item.payment_details="{ item }">
							<v-row no-gutters v-for="(payment, index) in item.payment_details" :key="index" class="d-flex justify-left">
								<v-col>{{ payment.channel_name }}</v-col>
								<v-col>{{ payment.credits }}</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import api from "../services/api.js";

export default {
	components: {},
	data() {
		return {
			loading: true,
			payment_details_dialog: false,
			payment_details: {},
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			panel_data: {
				panels: [],
			},
			itemsPerPage: -1,
			aggregated_data: [],
			payment_details_headers: [
				{ text: "Nombre", value: "customer.name", width: "150" },
				{ text: "Monto", value: "amount", width: "80" },
				{ text: "Tipo", value: "paid_via", width: "100" },
				{ text: "Comprobante", value: "voucher", width: "150" },
				{ text: "Subscripción / Créditos", value: "payment_details", width: "150" },
			],
		};
	},
	computed: {
		headers() {
			return Object.keys(this.aggregated_data.joined_data[0]).map((h) => {
				if (h === "payment_date") return { text: "Día", value: h };
				return { text: h.replace(/_/g, " ").toUpperCase(), value: h };
			});
		},
		available_credits() {
			return (this.panel_data.credits_available - this.panel_data.credits_used).toFixed(2);
		},
		notifications() {
			return this.$store.getters.notifications;
		},
	},

	methods: {
		close() {
			this.payment_details = {};
		},
		get_payment_details(day, channel_name, paid_via) {
			this.payment_details_dialog = true;

			if (channel_name) {
				channel_name = channel_name.replace("_", " ");
			}

			if (paid_via) {
				api.get_payment_details(this.year, this.month, day, null, paid_via).then((response) => {
					this.loading = false;
					this.payment_details = response.data;
				});
			} else {
				api.get_payment_details(this.year, this.month, day, channel_name).then((response) => {
					this.loading = false;
					this.payment_details = response.data;
				});
			}
		},
		get_style(column_name) {
			column_name = column_name.replace(" ", "_");
			return this.styles[column_name];
		},
		get_panels() {
			api.get_panels().then((response) => {
				this.panel_data = response.data;
			});
		},
		get_payments_aggregations() {
			api.get_payments_aggregations2(this.year, this.month).then((response) => {
				this.aggregated_data = response.data;
			});
		},
		edit_panel(panel, index) {
			this.panel_data.panels.splice(index, 1, { ...panel, edit: true });
		},
		save_panel(panel) {
			delete panel.edit;
			api.update_panel(panel).then(() => this.get_panels());
		},
	},
	mounted() {
		this.$nextTick(function () {
			this.get_payments_aggregations();
		});
	},
};
</script>
<style scoped>
/* table, th, tr, td {
    border: solid 1px;
} */
.centered {
	text-align: center;
}

.footer {
	font-weight: bolder;
	background-color: antiquewhite;
}
.total {
	/* background-color: blanchedalmond !important; */
	/* text-align: center; */
	color: black !important;
	font-weight: bolder !important;
	overflow-wrap: none;
}
</style>
