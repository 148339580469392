<template>
	<v-container fluid>
		<v-row>
			<v-btn class="mx-2" small color="info" fab @click="$router.back()">
				<v-icon>mdi-arrow-left </v-icon>
			</v-btn>
		</v-row>

		<v-row v-if="customer.active === false" justify="center">
			<v-col cols="12" align="center">
				<v-banner color="red" class="white--text font-weight-bold text-h6">Inactivo</v-banner>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col lg="3" md="3" sm="3" align-self="center">
				<v-container class="text-subtitle-2">
					<v-icon large>mdi-account-tie</v-icon>

					<v-text-field field v-model="customer.customer_no" :disabled="edit_disabled">
						<template v-slot:label>
							<div class="text-subtitle-2">No. Cliente</div>
						</template>
					</v-text-field>

					<v-text-field v-model="customer.name" :disabled="edit_disabled" label="Nombre" class="text-subtitle-2">
						<template v-slot:label>
							<div class="text-subtitle-2">Nombre</div>
						</template>
					</v-text-field>

					<v-text-field v-model="customer.email" :disabled="edit_disabled">
						<template v-slot:label>
							<div class="text-subtitle-2">Correo</div>
						</template>
					</v-text-field>

					<!-- <v-text-field v-model="customer.tel" :disabled="edit_disabled">
						<template v-slot:label>
							<div class="text-subtitle-2">Teléfono</div>
						</template>
					</v-text-field> -->
					<v-row dense>
						<v-col>
							<p class="grey--text text-caption">{{ country_name }}</p>
							<vue-tel-input-vuetify
								:disabled="edit_disabled"
								:error="!phone_valid"
								:preferred-countries="['CR', 'US']"
								:valid-characters-only="true"
								:inputOptions="{ showDialCode: true }"
								mode="international"
								placeholder="Ingrese el número de teléfono"
								label="Teléfono"
								@input="onInput"
								:value="customer.tel"
							/>
						</v-col>
					</v-row>

					<v-textarea auto-grow dense v-model="customer.nota1" :disabled="edit_disabled">
						<template v-slot:label>
							<div class="text-subtitle-2">Nota 1</div>
						</template>
					</v-textarea>

					<v-textarea auto-grow dense v-model="customer.nota2" :disabled="edit_disabled">
						<template v-slot:label>
							<div class="text-subtitle-2">Nota 2</div>
						</template>
					</v-textarea>

					<v-text-field v-model="customer.regular_payment" label="Pago Regular" prefix="$" :disabled="edit_disabled" />

					<v-checkbox :disabled="edit_disabled" v-model="customer.auto_charge" dense>
						<template v-slot:label>
							<div class="text-subtitle-2">Cargo automático</div>
						</template>
					</v-checkbox>

					<v-text-field :disabled="edit_disabled" v-if="customer.auto_charge === true" v-model="customer.auto_charge_day">
						<template v-slot:label>
							<div class="text-subtitle-2">Día cargo</div>
						</template>
					</v-text-field>

					<v-checkbox :disabled="edit_disabled" v-model="customer.referred" dense>
						<template v-slot:label>
							<div class="text-subtitle-2">Referido</div>
						</template>
					</v-checkbox>

					<v-checkbox :disabled="edit_disabled" v-model="customer.demo" dense>
						<template v-slot:label>
							<div class="text-subtitle-2">Demo</div>
						</template>
					</v-checkbox>

					<v-autocomplete :disabled="edit_disabled" v-if="customer.referred" v-model="customer.referredId" :items="customers">
						<template v-slot:item="data">
							<v-list-item-content v-text="data.item.text" class="text-subtitle-2"></v-list-item-content>
						</template>

						<template v-slot:selection="data">
							<div v-bind="data.attrs" :input-value="data.selected" class="text-subtitle-2">
								{{ data.item.text }}
							</div>
						</template>
					</v-autocomplete>
				</v-container>

				<v-container>
					<v-row justify="space-around">
						<v-btn color="info" @click="edit_disabled = !edit_disabled" v-if="edit_disabled">Editar</v-btn>
						<v-btn color="info" v-else @click="cancel_save()">Cancelar</v-btn>
						<v-btn color="info" @click="save_customer()" v-if="!edit_disabled" :disabled="!phone_valid">Guardar</v-btn>

						<v-btn v-if="customer.active && edit_disabled" color="error" @click="toggle_customer(false)">Desactivar</v-btn>
						<v-btn v-if="!customer.active && edit_disabled" color="success" @click="toggle_customer(true)">Activar</v-btn>
					</v-row>
				</v-container>
			</v-col>
			<v-col>
				<v-tabs v-model="tab" centered icons-and-text>
					<v-tab
						>Subscripciones
						<v-icon>mdi-devices</v-icon>
					</v-tab>
					<v-tab
						>Pagos
						<v-icon>mdi-cash-multiple</v-icon>
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item>
						<CustomerDevices :customer="customer" :key="customer_devices_key" @get_customer="get_customer()" />
					</v-tab-item>

					<v-tab-item>
						Pagos
						<PaymentsHistComponent @get_customer="get_customer()" :customer="customer" :key="payments_key" />
					</v-tab-item>
				</v-tabs-items>

                <v-row>
            <Chat :customer_id="this.$route.query.id"/>
        </v-row>


			</v-col>
		</v-row>




	</v-container>
</template>
<script>
import api from "../services/api";
import PaymentsHistComponent from "./PaymentsHistComponent.vue";
import CustomerDevices from "./Customer_Devices.vue";
import Chat from "./Chat.vue";

export default {
	components: { CustomerDevices, PaymentsHistComponent, Chat },
	data() {
		return {
			country_name: "",
			phone_valid: false,
			phone_delete: "",
			nota1_updated: false,
			tab: null,
			customer: {},
			edit_disabled: true,
			customer_devices_key: 0, // hack to forace customer devices to refresh after payment
			payments_key: 0, // hack to force payments tab refresh
			//   customers: this.$store.getters.customers
		};
	},
	methods: {
		onInput(formattedNumber, { number, valid, country }) {
			// this.formattedNumber = formattedNumber;
			this.country_name = country.name;
			this.customer.tel = number.international;
			this.phone_valid = valid;
			// this.message.phone.country = country && country.name;
		},
		get_customer() {
			api.get_customer(this.$route.query.id, this.$store.getters.days_filter).then((response) => {
				this.customer = response.data;
				this.customer_devices_key += 1;
				this.payments_key += 1;
			});
		},
		cancel_save() {
			this.edit_disabled = !this.edit_disabled;
			this.nota1_updated = false;
			this.get_customer();
		},
		save_customer() {
			api.update_customer(this.customer, this.nota1_updated).then((response) => {
				if (response.status === 200) {
					this.edit_disabled = true;
					this.nota1_updated = false;
					this.$store.dispatch("get_notifications", this.$store.getters.user.id);
				}

				if (response.status === 500) {
					console.log("error");
					console.log(response);
				}
			});
		},
		toggle_customer(status) {
			this.customer.active = status;
			api.update_customer(this.customer).then((response) => {
				if (response.status == 200) {
					this.get_customer();
				}
			});
		},
	},
	computed: {
		total_amount() {
			return this.customer.subscriptions.map((subscriptions) => subscriptions.channel.cost).reduce((acc, cost) => acc + cost);
		},
		customers() {
			return this.$store.getters.customers.map((customer) => {
				return {
					value: customer.id,
					text: customer.name + " | " + customer.customer_no,
				};
			});
		},
	},
	watch: {
		"customer.nota1": function (newValue) {
			if (this.edit_disabled === false && newValue != undefined && this.nota1_updated === false) {
				this.nota1_updated = true;
			}
		},
		$route() {
			this.get_customer();
		},
	},
	mounted() {
		this.get_customer();
	},
};
</script>
