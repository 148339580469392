<template>
	<v-container fluid>
		<v-container v-if="aggregated_data" fluid>
			<v-container>
				<v-row align="center">
					<v-col cols="1"><v-text-field field v-model="year" type="number" label="Año" /></v-col>
					<v-col cols="1"><v-text-field field v-model="month" type="number" label="Mes" /></v-col>
					<v-col cols="2"><v-btn @click="get_payments_aggregations()" color="primary" elevation="2" small>Ir</v-btn></v-col>
				</v-row>
			</v-container>

			<v-simple-table v-if="aggregated_data.data.length > 0">
				<thead>
					<tr style="vertical-align: baseline">
						<th style="text-align: center">Día</th>
						<th v-for="(value, name) in aggregated_data.data[0].methods" :key="name">
							<p v-if="name == 'Total_Metodos'" class="total">{{ name }}</p>
							<p v-else>{{ name }}</p>
						</th>
						<th v-for="(value, name) in aggregated_data.data[0].credits" :key="name" style="text-align: center">
							<p v-if="name == 'Total_Creditos'" class="total">
								{{ name }}
							</p>
							<p v-else>{{ name }}</p>
						</th>
					</tr>
				</thead>
				<tbody class="centered">
					<tr v-for="item in aggregated_data.data" :key="item.day">
						<td style="text-align: center">
							<v-btn color="#F9F7F7" elevation="2" class="font-weight-bold" rounded small @click="get_payment_details(item.payment_day)">{{
								item.payment_day
							}}</v-btn>
						</td>

						<td v-for="(value, name) in item.methods" :key="name">
							<p v-if="name === 'Total_Metodos'" class="total">${{ value }}</p>
							<p v-else>{{ value }}</p>
						</td>

						<td v-for="(value, name) in item.credits" :key="name">
							<v-btn
								v-if="name != 'Total_Creditos'"
								color="#F9F7F7"
								class="font-weight-bold"
								rounded
								small
								@click="get_payment_details(item.payment_day, name)"
								>{{ value }}</v-btn
							>
							<span v-else class="total">{{ value }}</span>
						</td>
					</tr>
					<tr class="footer">
						<td></td>
						<td v-for="(value, name) in aggregated_data.amount_totals" :key="name">
							<v-btn v-if="name != 'TOTAL'" @click="get_payment_details(null, null, name)" class="font-weight-bold" rounded>${{ value }}</v-btn>
							<span v-else class="total">{{ value }}</span>
						</td>
						<td v-for="(value, name) in aggregated_data.credits_totals" :key="name">{{ value }}</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-container>

		<v-dialog v-model="payment_details_dialog" @input="close">
			<v-card>
				<v-card-title>
					<v-row>Pagos</v-row>
				</v-card-title>
				<v-card-text>
					<v-data-table :items="Object.values(payment_details)" :headers="payment_details_headers" disable-pagination hide-default-footer :loading="loading">
						<template v-slot:item.payment_details="{ item }">
							<v-row no-gutters v-for="(payment, index) in item.payment_details" :key="index" class="d-flex justify-left">
								<v-col>{{ payment.channel_name }}</v-col>
								<v-col>{{ payment.credits }}</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import api from "../services/api.js";

export default {
	components: {},
	data() {
		return {
			loading: true,
			payment_details_dialog: false,
			payment_details: {},
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			panel_data: {
				panels: [],
			},
			itemsPerPage: -1,
			aggregated_data: {
				data: [],
				amount_totals: {},
				credits_totals: {},
			},
			payment_details_headers: [
				{ text: "Nombre", value: "customer.name", width: "150" },
				{ text: "Monto", value: "amount", width: "80" },
				{ text: "Tipo", value: "payment_method.name", width: "100" },
				{ text: "Comprobante", value: "voucher", width: "150" },
				{ text: "Subscripción / Créditos", value: "payment_details", width: "150" },
			],
		};
	},
	computed: {
		available_credits() {
			return (this.panel_data.credits_available - this.panel_data.credits_used).toFixed(2);
		},
		notifications() {
			return this.$store.getters.notifications;
		},
	},
	methods: {
		close() {
			this.payment_details = {};
		},
		get_payment_details(day, channel_name, paid_via) {
			this.payment_details_dialog = true;

			if (channel_name) {
				channel_name = channel_name.replace("_", " ");
			}

			if (paid_via) {
				api.get_payment_details(this.year, this.month, day, null, paid_via).then((response) => {
					this.loading = false;
					this.payment_details = response.data;
				});				
			} else {
				api.get_payment_details(this.year, this.month, day, channel_name).then((response) => {
					this.loading = false;
					this.payment_details = response.data;
				});
			}
		},
		get_style(column_name) {
			column_name = column_name.replace(" ", "_");
			return this.styles[column_name];
		},
		get_panels() {
			api.get_panels().then((response) => {
				this.panel_data = response.data;
			});
		},
		get_payments_aggregations() {
			api.get_payments_aggregations(this.year, this.month).then((response) => {
				this.aggregated_data = response.data;
			});
		},
		edit_panel(panel, index) {
			this.panel_data.panels.splice(index, 1, { ...panel, edit: true });
		},
		save_panel(panel) {
			delete panel.edit;
			api.update_panel(panel).then(() => this.get_panels());
		},
	},
	mounted() {
		this.$nextTick(function () {
			this.get_payments_aggregations();
		});
	},
};
</script>
<style scoped>
.centered {
	text-align: center;
}

.footer {
	font-weight: bolder;
	background-color: antiquewhite;
}
.total {
	/* background-color: blanchedalmond !important; */
	/* text-align: center; */
	color: black;
	font-weight: bolder;
	overflow-wrap: none;
}
</style>
