<template>
	<v-container>        
        <v-row v-if="deactivate && subscription_totals > 0">
            <v-col>
               <p class="text-subtitle-1 font-weight-black">Si desactiva este panel, estas subscripciones serán eliminadas</p>
            </v-col>
        </v-row>
		<v-row no-gutters justify="center">
            <v-col cols="10">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Estado</th>
                                <th>Conteo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="count in subscription.counts" :key="count.status">
                                <td>{{ count.status }}</td>
                                <td>{{ count.count }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
		</v-row>


		<div v-if="deactivate" align-content="center" justify="center">
    
            <v-row align-content="center" justify="center">
                <v-col align="center">
                    <v-btn color="error" @click="confirm_delete()">Confirmar</v-btn>
                </v-col>
            </v-row>

			<span v-if="subscription_totals > 0">

				<v-row align-content="center" justify="center">
					<v-col align="center">
						<v-btn small color="primary" @click="get_subscriptions()">Ver subscripciones</v-btn>
					</v-col>
				</v-row>
				<v-row>
                    <v-col>
					<v-simple-table v-if="subscriptions.length > 0">
						<template v-slot:default>
							<thead>
								<tr>
									<th>Num. Cliente</th>
									<th>Nombre</th>
                                    <th>Próx. Pago</th>
									<th>Estado</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="subscription in subscriptions" :key="subscription.id">
									<td>{{ subscription.customer.customer_no }}</td>
									<td>{{ subscription.customer.name }}</td>
                                    <td>{{ subscription.next_payment_date | formatDate }}</td>
									<td>
										<v-chip class="ma-2" :color="get_tag(subscription.status)" text-color="black" x-small>
											{{ subscription.status }}
										</v-chip>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
                </v-col>
				</v-row>
			</span>

		</div>
	</v-container>
</template>

<script>
import api from "../services/api";

export default {
	props: ["subscription", "deactivate"],
	data() {
		return {
			subscriptions: [],
		};
	},
	computed: {
		subscription_totals() {
			return this.subscription.counts.reduce((acc, s) => {
				return Number(s.count) + acc;
			}, 0);
		},
	},
	methods: {
		get_tag(status) {
			if (status == "activo") {
				return "success";
			} else if (status === "recordar") {
				return "warning";
			} else if (status === "expirado") {
				return "error";
			}
		},
		get_subscriptions() {
			api.get_subscriptions(this.subscription.id).then((response) => {
				this.subscriptions = response.data;
			});
		},
        confirm_delete() {
            api.disable_channel(this.subscription.id).then((response) => {
                if (response.status === 200) {
                    this.$emit("get_subscriptions_aggregations")
                    this.$store.dispatch("get_customers")
                }
            })
        }
	},
	mounted() {},
};
</script>
