<template>
	<v-container fluid>
		<v-container>
			<v-row justify="center"><p class="text-h5">Pagos</p></v-row>
		</v-container>

		<v-container>
			<v-row justify="center">
				<v-simple-table dense v-if="payment_records.length > 0">
					<thead>
						<tr>
							<th v-for="header in grouped_headers" :key="header.value">{{ header.text }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(value, name) in grouped_payments" :key="name">
							<template v-if="value.paid_via != 'Total'">
								<td>{{ value.paid_via }}</td>
								<td>${{ value.sum }}</td>
								<td>{{ value.count }}</td>
							</template>
							<template v-else>
								<td class="font-weight-black">{{ value.paid_via }}</td>
								<td class="font-weight-black">${{ value.sum }}</td>
								<td class="font-weight-black">{{ value.count }}</td>
							</template>
						</tr>
					</tbody>
				</v-simple-table>
			</v-row>
		</v-container>

		<v-container fluid>
			<v-row align="center" justify="start">
				<v-col cols="6">
					<v-row>
						<v-col cols="2"><v-text-field field v-model="year" type="number" label="Año" /></v-col>
						<v-col cols="2"><v-text-field field v-model="month" type="number" label="Mes" /></v-col>
						<v-col cols="2" align-self="center"><v-btn @click="get_all_payments()" color="primary" elevation="2" small>Ir</v-btn></v-col>
					</v-row>
				</v-col>
				<v-col cols="4">
					<v-row align="center" justify="start">
						<v-col>
							<v-text-field label="Nuevo nuevo método de pago" v-model="new_payment_method">Tipo</v-text-field>
						</v-col>
						<v-col>
							<v-btn small elevation="2" color="primary" @click="add_payment_method()">Agregar</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<v-row justify="center">
			<v-col>
				<v-card>
					<v-card-title>
						<v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
					</v-card-title>

					<v-data-table :items="payment_records" :headers="headers" sort-by="id" sort-desc :search="search" disable-pagination hide-default-footer>
						<template v-slot:item.payment_date="{ item }">
							{{ item.payment_date | formatDate }}
						</template>

						<template v-slot:item.payment_details="{ item }">
							<div class="d-flex justify-left" v-for="(payment, index) in item.payment_details" :key="index">
								<p v-if="payment.channel_name">{{ payment.channel_name.replace(/_/g, " ") }}</p>
							</div>
						</template>

						<template v-slot:item.credits="{ item }">
							<div v-for="(payment, index) in item.payment_details" :key="index" class="d-flex justify-center">
								{{ payment.credits }}
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<vue-json-to-csv :json-data="export_data" :csv-title="'pagos'">
					<v-btn color="success" class="mr-6"> Exportar <i class="mdi mdi-export-variant"></i> </v-btn>
				</vue-json-to-csv>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import api from "../services/api";

export default {
	components: { VueJsonToCsv },
	data() {
		return {
			search: "",
			payment_records: [],
			grouped_payments: {},
            new_payment_method: "",
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			grouped_headers: [
				{ text: "Tipo de pago", value: "paid_via" },
				{ text: "Suma", value: "sum" },
				{ text: "Cantidad", value: "count" },
			],
			headers: [
				{ text: "Nombre", value: "customer.name" },
				{ text: "Num. Cliente", value: "customer.customer_no" },
				{ text: "Subscripciones", value: "payment_details" },
				{ text: "Dispositivo", value: "device" },
				{ text: "Cantidad", value: "quantity" },
				{ text: "Créditos", value: "credits", align: "center" },
				{ text: "Fecha", value: "payment_date" },
				{ text: "Monto", value: "amount" },
				// { text: "Tipo", value: "payment_type" },
				{ text: "Tipo de pago", value: "paid_via" },
				{ text: "Comprobante", value: "voucher" },
				{ text: "Usuario", value: "user" },
			],
		};
	},
	methods: {
        add_payment_method() {
            api.new_payment_method(this.new_payment_method).then((response) => {
                if (response.status === 200) {
                    this.$store.dispatch("get_payment_methods")
                    this.new_payment_method = ""
                }
            })
        },
		get_all_payments() {
			api.get_all_payments(this.year, this.month).then((response) => {
				this.payment_records = response.data.payments;
				this.grouped_payments = response.data.grouped_payment_credits;
			});
		},
	},
	computed: {
		export_data() {
			return this.payment_records.map((row) => {
				return {
					nombre: row.customer ? row.customer.name : "",
					subscripciones: row.payment_details.map((payment) => {
						return payment.channel_name;
					}),
					creditos: row.payment_details.map((payment) => {
						return payment.credits;
					}),
					fecha: row.payment_date,
					monto: row.amount,
					comprobante: row.voucher,
					usuario: row.user,
					tipo: row.payment_type,
				};
			});
		},
	},
	mounted() {
		this.get_all_payments(this.year, this.month);
	},
};
</script>

<style></style>
