<template>
  <v-container>
    <v-container>
      <v-row justify="center"><p class="text-h5">Clientes con subscripciones expiradas</p></v-row>
    </v-container>

    
    <v-text-field v-model="days" label="Días (negativo)" />
    <v-btn color="primary" @click="get_expired_customers()">Obtener</v-btn>

    <v-data-table
      class="table is-striped is-size-6"
      :headers="columns"
      :items="expired_customers"
      :search="search"
      disable-pagination
      items-per-page.sync="15"
      sort-by="customer_no"
      page.sync="1"
    >
      <template v-slot:item.subscriptions="{ item }">
        <v-row
          no-gutters
          v-for="subscription in item.subscriptions"
          :key="subscription._id"
        >
          <v-col v-if="subscription.channel.name" cols="5" sm="3" md="3"> {{ subscription.channel.name.replace(/_/g, " ") }}</v-col>
          <v-col cols="4" md="3" sm="4"
            >{{ subscription.days_left }} días</v-col
          >
        </v-row>
      </template>

      <template v-slot:item.active="{ item }">
        <p v-if="item.active">activo</p>
        <p v-else class="red--text text--darken-1 font-weight-bold">inactivo</p>
      </template>

      <template v-slot:item.details="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="info"
          @click="navigate(item.id)"
        >
          <v-icon dark> mdi-magnify </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import api from "../services/api"

export default {
  data() {
    return {
      search: "",
      days: -15,
      expired_customers: [],
      columns: [
        { text: "Num. Cliente", value: "customer_no", width: "100" },
        { text: "Nombre", value: "name", searchable: true, width: "300" },
        { text: "Estado", value: "active" },
        // { text: "Email", value: "email", searchable: true },
        // { text: "Tel", value: "phone" },
        { text: "Subscripciones", value: "subscriptions" },
        { text: "Detalles", value: "details" },
      ],
    };
  },
  methods: {
    navigate(id) {
      this.$router.push({ name: "customer", query: { id: id } });
    },
    get_expired_customers() {
      api.get_expired_customers(this.days).then((response) => {
        this.expired_customers = response.data;
      })
    }
  },
  mounted() {
    this.days = -3
    this.get_expired_customers();
  },
};
</script>