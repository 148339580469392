<template>
	<v-container>
		<v-data-table 
            :items="referrals" 
            :headers="headers"
            disable-pagination
            hide-default-footer
            >

            <template v-slot:item.customer_no="{ item }">
                <v-btn icon rounded text color="info" @click="navigate(item.id)">{{item.customer_no}}</v-btn>
            </template>
			<template v-slot:item.referrals="{ item }">
				<v-row v-for="referred in item.referrals" :key="referred.id">
                    <v-col cols="2">
						<v-btn icon rounded text color="info" @click="navigate(referred.id)">{{ referred.customer_no}}</v-btn>
					</v-col>
					<v-col cols="8">
						{{` ${referred.name} (${referred.customer_no})` }}
					</v-col>
				</v-row>

			</template>
		</v-data-table>
	</v-container>
</template>
<script>
import api from "../services/api";

export default {
	data() {
		return {
			referrals: [],
			headers: [
				{ text: "Num. Cliente", value: "customer_no", width: '10%' },
				{ text: "Nombre", value: "name", width: '30%', },
				{ text: "Referidos", value: "referrals", width: '60%',  align: 'left' },
			],
		};
	},
    methods: {
        navigate(id) {
            this.$router.push({ name: "customer", query: { id: id } });
        },
    },
	mounted() {
		api.get_referrals().then((response) => {
			this.referrals = response.data;
		});
	},
};
</script>
