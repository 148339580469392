<template>
	<div>
		<v-container>
			<p class="text-h4" align="center">Manejo de dispositivos</p>
		</v-container>

		<!-- <v-dialog v-if="type_add_stock" v-model="type_add_stock" max-width="500">
			<v-card>
				<v-card-title>Agregar Stock</v-card-title>
				<v-card-text>
					<span class="font-weight-black text-body-1">{{ edit_device_type.type }}</span>
					<v-text-field type="number" v-model="edit_device_type.quantity" label="Cantidad" />
					<v-text-field type="number" v-model="edit_device_type.amount" label="Monto" prefix="$" />
					<v-btn color="info" small @click="increment_stock()">Guardar</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog> -->

		<v-container>
			<v-row>
				<v-col v-for="(device_type, index) in device_types" :key="device_type.id" cols="4">
					<v-card>
						<v-card-title>
							<p v-if="!device_type.edit">{{ device_type.type }}</p>
							<v-text-field v-else v-model="device_type.type" />
						</v-card-title>
						<v-card-text>
							<v-simple-table>
								<tbody>
									<tr>
										<td>Precio de venta</td>
										<td>
											<p v-if="!device_type.edit">${{ device_type.sell_price }}</p>
											<v-text-field v-else v-model="device_type.sell_price" prefix="$" />
										</td>
									</tr>
									<tr>
										<td>Stock</td>
										<td>{{ device_type.stock }}</td>
									</tr>
									<tr>
										<td>Vendidos</td>
										<td>{{ device_type._count.devices }}</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
						<v-card-actions>
							<v-btn x-small color="warning" @click="add_stock(device_type, index)"> Agregar Stock </v-btn>
							<v-btn x-small color="info" @click="edit_device_type(device_type, index)">Editar </v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="device_type.type_add_stock || device_type.edit">
								<v-card-text>
									<v-text-field v-show="device_type.type_add_stock" type="number" v-model="device_type.quantity" label="Cantidad" />
									<v-text-field v-show="device_type.type_add_stock" type="number" v-model="device_type.amount" label="Monto del pago" prefix="$" />

								</v-card-text>
                                <v-card-actions>
									<v-btn v-show="device_type.type_add_stock" color="info" small @click="increment_stock(device_type)">Guardar</v-btn>
									<v-btn v-show="device_type.edit" color="success" small @click="update_device_type(device_type)">Guardar</v-btn>
                                </v-card-actions>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col align-self="center">
					<v-btn x-large fab color="primary" @click="new_device_type_dialog = true">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-container>
			<v-sheet elevation="1">
				<v-row justify="center">
					<v-col align="center"> TOTALES </v-col>
				</v-row>
				<v-row>
					<v-col align="center">
						<p>Stock: {{ stock_totals }}</p>
						<p>Vendidos: {{ sold_totals }}</p>
					</v-col>
				</v-row>
			</v-sheet>
		</v-container>

		<v-container>
			<v-dialog v-if="new_device_type_dialog" v-model="new_device_type_dialog" @input="close" max-width="500">
				<v-card>
					<v-card-title>Nuevo Tipo</v-card-title>
					<v-card-text>
						<v-row>
							<v-col>
								<v-text-field v-model="new_device_type.type" label="Nombre" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field v-model="new_device_type.sell_price" label="Precio de venta" type="number" prefix="$" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn small color="info" @click="save_device_type()">Guardar</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-container>

		<v-container>
			<v-row>
				<v-col>
					<v-data-table :headers="device_headers" :items="devices" disable-pagination hide-default-footer>
						<template v-slot:item.createdAt="{ item }">
							<p>{{ item.createdAt | formatDate }}</p>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn color="primary" small @click="$router.push('new_device')">Agregar</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import api from "../services/api";

export default {
	data() {
		return {
			devices: [],
			device_types: [],
			new_device_type_dialog: false,
			new_device_type: {},
			device_headers: [
				{ text: "Tipo", value: "type.type" },
				{ text: "Serial", value: "serial" },
				{ text: "Cliente", value: "customer.name" },
				{ text: "Fecha", value: "createdAt" },
			],
		};
	},
	computed: {
		stock_totals() {
			return this.device_types.reduce((acc, x) => Number(x.stock) + acc, 0);
		},
		sold_totals() {
			return this.device_types.reduce((acc, x) => Number(x._count.devices) + acc, 0);
		},
	},
	methods: {
		edit_device_type(device_type, index) {
			this.device_types.splice(index, 1, {
				...device_type,
				edit: true,
				type_add_stock: false,
			});
		},
		increment_stock(device_type) {
			api
				.increment_stock({
					...device_type,
					user: this.$store.getters.user.username,
				})
				.then((response) => {
					if (response.status === 200) {
						this.type_add_stock = false;
						this.get_device_types();
					}
				});
		},
		add_stock(device_type, index) {
			this.device_types.splice(index, 1, { ...device_type, type_add_stock: true, edit: false });
		},
		get_device_types() {
			api.get_device_types().then((response) => {
				this.device_types = response.data;
				this.device_types.map((device_type) => {
					device_type.type_add_stock = false;
					device_type.edit = false;
					return device_type;
				});
			});
		},
		get_devices() {
			api.get_devices().then((response) => (this.devices = response.data));
		},
		close() {},
		update_device_type(device_type) {
            api.update_device_type(device_type).then((response) => {
                if (response.status ===200) {
                    this.get_device_types()
                }
            })
		},
		save_device_type() {
			api.new_device_type(this.new_device_type).then((response) => {
				if (response.status === 200) {
					this.get_device_types();
					this.new_device_type_dialog = false;
					this.new_device_type = {};
				}
			});
		},
	},
	mounted() {
		this.get_device_types();
		this.get_devices();
	},
};
</script>
