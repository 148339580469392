<template>
    <v-row class="mr-2">
      <!-- <v-col cols="10" justify align-self="center" style="border: 1px yellow solid;" class="ma-2"> -->
        <v-col>
        <a :href="excel_link" target="_blank" v-if="excel_link">
          <v-icon medium color="green"> mdi-file-excel </v-icon>
        </a>
    </v-col>
    <v-col>
        <a :href="word_link" target="_blank" v-if="word_link">
          <v-icon medium color="blue"> mdi-file-word </v-icon>
        </a>
    </v-col>
    <v-col>
        <a :href="gmail_link" target="_blank" v-if="gmail_link">
          <v-icon medium color="red">mdi-google</v-icon>
        </a>
    </v-col>
    <v-col>
        <a :href="gdrive_link" target="_blank" v-if="gdrive_link">
          <v-icon medium color="yellow">mdi-google-drive</v-icon>
        </a>
    </v-col>
      <!-- </v-col> -->
    </v-row>
</template>
<script>
export default {
  computed: {
    excel_link() {
      return process.env.VUE_APP_EXCEL_LINK || null;
    },
    word_link() {
      return process.env.VUE_APP_WORD_LINK || null;
    },
    gmail_link() {
      return process.env.VUE_APP_GMAIL_LINK || null;
    },
    gdrive_link() {
      return process.env.VUE_APP_GDRIVE_LINK || null;
    },
  },
};
</script>
