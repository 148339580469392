<template>
    <v-container>
        <v-row justify="space-around">
                <v-col>
                {{ subscription.channel.name }}</v-col>
                <v-col>
                {{ next_payment_date_comp | formatDate }}</v-col>

                <v-col>
                <v-chip class="ma-2" :color="get_tag(subscription.status)" text-color="black" x-small>
                    {{ subscription.status }}
                </v-chip>
            </v-col>
        </v-row>

        <v-row v-if="edit" justify="center">
            <v-col cols="4">
                <p>{{ panel.name }} / {{ available_credits }}</p>
                <p>Días: {{ subscription.days_left }}</p>

                <v-form v-model="isFormValid">
                    <v-text-field hint="Créditos" persistent-hint v-model="credits" dense min="0"
                        type="number" :rules="[credits_rules.min]" />
                </v-form>
                <v-container>
                    <p>${{ payment_result }}</p>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment";
// import { mapState } from "vuex";
import api from "../services/api";
export default {
    props: ["edit", "subscription", "customer"],
    data() {
        return {
            delete_dialog: false,
            isFormValid: false,
            credits_rules: {
                min: (value) => value > 0,
                max: (value) => value <= this.panel.credits,
            },
            panel: {},
            next_payment_date_comp: this.subscription.next_payment_date,
            payment_info_comp: {},
            credits: 0,
            available_credits: 0,
        };
    },
    watch: {
        credits: function () {
            if (this.edit) {
                if (this.credits > 0) {
                    this.available_credits = this.panel.credits - this.credits;
                } else {
                    return 0; // este else nunca se ejecuta porque viene con 0 desde MakePayment y porque el watch no se ejecuta inicialmente
                }

                if (this.subscription.status == "expirado") {
                    this.next_payment_date_comp = moment()
                        .add(this.credits, "months")
                        .format("YYYY-MM-DD HH:mm:ss");
                } else {
                    this.next_payment_date_comp = moment(
                        this.subscription.next_payment_date
                    )
                        .add(this.credits, "months")
                        .format("YYYY-MM-DD HH:mm:ss");
                }
            } else {
                return this.subscription.next_payment_date;
            }
        },
    },
    computed: {
        payment_result() {
            if (this.credits > 0) {
                return this.subscription.channel.cost * this.credits;
            } else {
                return 0;
            }
        },
    },
    methods: {
        get_tag(status) {
            if (status == "activo") {
                return "success";
            } else if (status === "recordar") {
                return "warning";
            } else if (status === "expirado") {
                return "error";
            }
        },
    },
    mounted() {
        this.$nextTick(function () {
            api.get_panel(this.subscription.channel.panelId).then((response) => {
                this.panel = response.data;
            });

            this.available_credits = this.panel.credits;
        });
    },
};
</script>

<style>
</style>