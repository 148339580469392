<template>
  <v-app>
    <v-main>
      <Nav></Nav>
      <router-view />
    </v-main>
    <v-footer dark>
      <p class="text-caption" align="end">
        {{ user.username }} | v{{ appVersion }}
      </p>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Nav from "./components/Nav.vue";
import { version } from "../package";

export default {
  name: "Main",
  components: {
    Nav,
},
  data() {
    return {
      appVersion: version,
    };
  },
  methods: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    })   
  },
};
</script>
