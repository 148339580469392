<template>
  <v-container>
    <v-snackbar v-model="snackbar">
      {{ new_message }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-data-table
      :headers="headers"
      :items="messages"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.created_at="{ item }">
        <p>{{ item.created_at | formatDate }}</p>
      </template>

      <template v-slot:item.sent="{ item }">
        <v-icon v-if="item.sent" color="green">mdi-check</v-icon>
      </template>

      <template v-slot:item.delivered="{ item }">
        <v-icon v-if="item.delivered" color="green darken-2"
          >mdi-check-all</v-icon
        >
      </template>

      <template v-slot:item.read="{ item }">
        <v-icon v-if="item.read" color="blue darken-2">mdi-check-all</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import SocketioService from "../services/socketioservice";
import api from "../services/api";

export default {
  data() {
    return {
      snackbar: false,
      new_message: "",
      headers: [
        { text: "id", value: "id", width: "1px" },
        { text: "Inbox", value: "inbox.name" },
        { text: "Cliente_Meta", value: "customer_meta.name" },
        { text: "Cliente_Tel_Meta", value: "customer_meta.phone_number" },
        { text: "Cliente", value: "customer.name" },
        { text: "Teléfono", value: "customer.tel" },
        { text: "Num. Cliente", value: "customer.customer_no" },
        { text: "Mensaje", value: "content" },
        { text: "Estado", value: "status" },
        { text: "Fecha", value: "created_at" },
      ],
      messages: [],
    };
  },
  created() {
    SocketioService.setupSocketConnection();
    console.log("socket connection set up");

    SocketioService.socket.on("wam_status_change", (data) => {
      console.log("wam_status_change");
      this.update_messages(data);
    });

    SocketioService.socket.on("new_message", (data) => {
      console.log(data);
    });

    SocketioService.socket.on("connected", () => {
      console.log("socket connected");
    });

    SocketioService.socket.on("disconnected", () => {
      console.log("socket disconnected");
    });

    this.get_messages();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  methods: {
    get_messages() {
      api.get_messages().then((response) => {
        this.messages = response.data;
      });
    },
    update_messages(data) {
      this.snackbar = true;
      this.new_message = data.content;
      var message_to_update_index = this.messages.findIndex(
        (message) => message.source_id === data.source_id
      );

      if (message_to_update_index != -1) {
        var status = data.status;
        // var status_value = Object.values(data.status);
        console.log(
          "updating wam_id " +
            this.messages[message_to_update_index].source_id +
            " with status " +
            status
        );

        this.messages[message_to_update_index].status = status;
      } else {
        // this data is incomplete as it's coming from the webhook without customer data
        // this.messages.push(data)
        // one possible workaround might be to query the API for specific wam_id, for now I'll refresh everything
        console.log("refreshing messages");
        this.get_messages();
      }
    },
  },
};
</script>
