<template>
	<v-container>
		<v-snackbar v-model="customer_saved">
			{{ customer_saved_ok }}

			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
			</template>
		</v-snackbar>

		<v-stepper v-model="activeStep" vertical>
			<v-stepper-step :complete="activeStep > 1" step="1" editable> Crear Cliente </v-stepper-step>
			<v-stepper-content step="1">
				<NewCustomerForm ref="new_customer_form" />
			</v-stepper-content>

			<v-stepper-step :complete="activeStep > 2" step="2" editable> Crear Subscripción </v-stepper-step>

			<v-stepper-content step="2"><NewSubscriptionForm ref="new_subscriptions_form" /></v-stepper-content>

			<v-stepper-step :complete="activeStep === 3" step="3" editable> Crear Pago </v-stepper-step>
			<v-stepper-content step="3">
				<v-container>
					<v-select label="Tipo de pago" :items="payment_types" item-text="name" item-value="id" v-model="payment_info.methodId" />
				</v-container>
				<v-container>
					<v-text-field label="Comprobante" v-model="payment_info.voucher" persistent-hint />
				</v-container>

				<v-container>
					<!-- <v-text-field
            label="Fecha de pago"
            v-model="payment_info.payment_date"
            persistent-hint
          /> -->

					<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="payment_info.payment_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="payment_info.payment_date" @input="menu = false" locale="es"></v-date-picker>
					</v-menu>
				</v-container>

				<v-container>
					<v-text-field
						label="Monto"
						v-model.number="payment_info.total_amount"
						type="number"
						prefix="$"
						:hint="'$' + String(suggested_total_amount)"
						persistent-hint
					/>
				</v-container>
			</v-stepper-content>
		</v-stepper>
		<v-row>
			<v-col cols="1">
				<v-btn color="info" :disabled="activeStep != 3" @click="create_customer()">Guardar</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapState } from "vuex";
import NewCustomerForm from "./NewCustomerForm.vue";
import NewSubscriptionForm from "./NewSubscriptionForm.vue";
import api from "../services/api";
import moment from "moment";
// import { mapActions } from "vuex"

export default {
	components: { NewCustomerForm, NewSubscriptionForm },
	data() {
		return {
			all_channels: this.$store.getters.all_channels,
			activeStep: 1,
			hasNavigation: false,
			animated: true,
			rounded: true,
			stepClickable: true,
			new_customer: {},
			new_subscriptions: {},
			menu: false,
			payment_info: {
				user: this.$store.getters.user.username,
				methodId: "",
				voucher: "",
				payment_date: moment().toISOString(true).split("T")[0],
				total_amount: 0,
			},
			customer_saved_ok: "Cliente creado con éxito",
			customer_saved: false,
		};
	},
	methods: {
		// ...mapActions(['get_customers']),
		create_customer() {
			api
				.create_customer({
					customer: this.new_customer,
					subscriptions: this.new_subscriptions.filter((subscription) => subscription.channelId != ""),
					payment_info: this.payment_info,
				})
				.then((response) => {
					if (response.status == 200) {
						this.$store.dispatch("get_customers");
						this.customer_saved = true;
						this.$router.push({
							name: "customer",
							query: { id: response.data.id },
						});
					}
				});
		},
	},
	computed: {
        ...mapState({
           payment_types: (state) => state.payment_methods 
        }),
		suggested_total_amount() {
			if (this.new_subscriptions.length > 0) {
				return this.new_subscriptions
					.map((subscription) => {
						if (subscription.channelId != "") {
							return this.all_channels.find((channel) => channel.id == subscription.channelId).cost * subscription.credits;
						} else {
							return 0;
						}
					})
					.reduce(function (acc, cost) {
						return acc + cost;
					}, 0);
			} else {
				return 0;
			}
		},
	},
	mounted() {
		this.new_customer = this.$refs.new_customer_form.customer;
		this.new_subscriptions = this.$refs.new_subscriptions_form.subscriptions;
	},
};
</script>
